.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Raleway', sans-serif;
}

.calendar {
  background-color:   #BC261A;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Raleway', sans-serif;
  padding: 5vh 5vh;
}

.newsletter {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Raleway', sans-serif;
}

.calendar .iframe {
  width: 100vw;
  height: 60vw;
  max-width: 800px;
  max-height: 600px;
}

.p {
  padding: 0px;
}