
.mailchimp {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.mailchimp input {
 font-family: 'Raleway', sans-serif;
 margin: 8px;
 font-size: calc(10px + 2vmin);
}

.mailchimp button {
 font-family: 'Raleway', sans-serif;
 margin: 8px;
 font-size: calc(10px + 2vmin);
}